import React from "react";
import { AuthProvider } from "../../../AuthContext.js";
import RutasAdministrador from "../rutas/RutasAdministrador.js";
import Header from "../../../componentes/layouts/Headers.js";
import Footer from "../../../componentes/layouts/Footer.js";

const AppAdministrador = () => {
  return (
    <AuthProvider>
      <Header/>
      <div style={{ paddingTop: "6rem" }} className="container">
        <RutasAdministrador />
      </div>
      <Footer/>
    </AuthProvider>
  );
};

export default AppAdministrador;
