import axios from "axios";
import Swal from "sweetalert2";

const actualizarVentaServicio = async (detalles, metodos, descuentos) => {
  const confirmacion = await Swal.fire({
    title: "Actualizar Ventas",
    text: "¿Estás segura de actualizar esta venta?",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Sí, Actualizar",
  });

  if (confirmacion.isConfirmed) {
    try {
      const respuesta = await respuestaActualizarVenta(detalles, metodos, descuentos);
      return respuesta;
    } catch (error) {
      return error;
    }
  } else {
    return null;
  }
};

  const respuestaActualizarVenta = async (detalles, metodos, descuentos) => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `${token}`,
      }
    };
    const data = {
      detalles,
      metodos,
      descuentos
    }
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/dueno/actualizar-venta`,
        data,
        config
      );
      return [true, response.data, response.status];
    } catch (error) {
      return [false, error.data, error.status];
    }
  }

  export{
    actualizarVentaServicio
  }