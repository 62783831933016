import axios from "axios";

//TODO: Eliminar
const buscarVentaPorAdministradorAnioService = async () => {

}

//TODO: No funciona
const buscarVentaMesualPorAdministradorService = async (mes) => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `${token}`,
      }
    };

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/ventas/ventas-administrador`,
      config
    );
}

const buscarVentaPorAdministradorMesAnioService = async (mes, anio) => {
  const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `${token}`,
      }
    };
  try{
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/administrador/buscar-venta-mensual/${mes}/${anio}`,
      config
    );
    return [true, response.data];
  }catch(error){
    return [false, error.status, error.response.data];
  }
}

//buscar los años vendidos por el administrador
const buscarAniosRealizadosAdministradorService = async () => {
  const token = localStorage.getItem("token");
  const config = {
    headers: {
      Authorization: `${token}`,
    },
  };
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/administrador/buscar-venta-por-anio`,
      config
    );
    return [true, response.data];
  } catch (error) {
    return [false, error.status, error.response.data];
  }
};

const buscarVentasPorIdAdministradorService = async (id) => {
  const token = localStorage.getItem("token");
  const config = {
    headers: {
      Authorization: `${token}`,
    },
  };
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/administrador/buscar-venta-por-id/${id}`,
      config
    );
    return [true, response.data.venta, response.data.ventaEmpleados, response.data.pagos, response.data.descuentos, response.data.local];
  } catch (error) {
    return [false, error.status, error.response.data];
  }
};

export{
    buscarVentaPorAdministradorAnioService,
    buscarVentaMesualPorAdministradorService,
    buscarVentaPorAdministradorMesAnioService,
    buscarAniosRealizadosAdministradorService,
    buscarVentasPorIdAdministradorService
}