import React, { useEffect, useState } from "react";
import { AuthProvider, useAuth } from "./AuthContext.js";
import Header from "./componentes/layouts/Headers.js";
import AppAdministrador from "./dashboard/administrador/app/AppAdministrador.js";
import AppEmpleado from "./dashboard/empleados/app/AppEmpleado.js";
import AppDueno from "./dashboard/dueno/app/AppDueno.js";
import AppPartner from "./dashboard/partner/app/AppPartner.js";
import RecuperarPassword from "./componentes/verificacion/RecuperarPassword.js";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

//paginas de errores
import ExpiroSesion from "./errores/sesionExpiro.js";
import Error403 from "./errores/error403.js";
import Error404 from "./errores/error404.js";
import Carga from "./componentes/cargando/Cargando.js";
import Footer from "./componentes/layouts/Footer.js";

function Componentes() {
  const { isAuthenticated, verificacionToken } = useAuth();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (isAuthenticated) {
      const rol = localStorage.getItem("rol");
      verificacionToken(rol);
    }
    setIsLoading(false);
  }, []);

  if (isLoading) {
    return <Carga />;
  }

  return (
    <div className="main-content">
      <Header />

      {/* Contenido Principal */}
      <div className="content-wrapper">
        {!isAuthenticated ? (
          <div className="loading-message">En Contrucción</div>
        ) : (
          <h1 className="welcome-message">Hola Mundo desde la sesion</h1>
        )}
      </div>
      <Footer />
    </div>
  );
}

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Componentes />} />
          <Route path="/dueno/*" element={<AppDueno />} />
          <Route path="/administrador/*" element={<AppAdministrador />} />
          <Route path="/empleado/*" element={<AppEmpleado />} />
          <Route path="/partner/*" element={<AppPartner />} />
          <Route path="/expiro-sesion" element={<ExpiroSesion />} />
          <Route
            path="/restablecer-password/:id/:token"
            element={<RecuperarPassword />}
          />
          <Route path="/no-autorizado" element={<Error403 />} />
          <Route path="/no-encontrado" element={<Error404 />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
