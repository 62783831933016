const Footer = () => {
  return (
    <footer className="fixed-bottom bg-light bg-pink-200">
      <div className="container py-1">
        <div className="row">
          <div className="col-6">
            <p className="text-center fw-bold d-none d-sm-inline small">
              &copy; 2024 Centro Estética Antonia. Todos los derechos
              reservados.
            </p>
          </div>
          <div className="col-6">
            <div className="container text-center">
              <div className="fw-bold d-sm-inline small">Nuestra Redes Sociales: </div>
              <a
                className="p-1"
                href="https://wa.me//?text=Hola, Buenas"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="/static/img/whatsapp.png" alt="Whatsapp" width="30" draggable="false" />
              </a>
              <a
                className="p-1"
                href="https://www.instagram.com/antonia.estetica.calama_/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="/static/img/instagram.png"
                  alt="Instagram"
                  width="30"
                  draggable="false"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
