import { useEffect, useState } from "react";
import { useAuth } from "../../../AuthContext";
import Carga from "../../../componentes/cargando/Cargando";
import ConfiguracionesLocales from "../configuraciones/configuracionesLocales";
import ConfiguracionesMedioPago from "./configuracionesMedioPago";
import ConfiguracionesServicio from "./configuracionesServicio";

const ConfiguracionesDueno = () => {
  const { verificacionToken } = useAuth();

  const [cargando, setCargando] = useState(true);

  useEffect(() => {
    verificacionToken("ROLE_DUENO");
    setCargando(false);
  }, []);

  if (cargando) {
    return <Carga />;
  }

  return (
    <div className="pb-5">
      <div className="mt-2 mb-2"><ConfiguracionesLocales/></div>
      <div className="mt-2 mb-2"><ConfiguracionesServicio/></div>
      <div className="mt-2 mb-2 pb-5"><ConfiguracionesMedioPago/></div>
    </div>
  );
};

export default ConfiguracionesDueno;
