import { useEffect, useState } from "react";
import { useAuth } from "../../../AuthContext";
import {
  buscarAniosRealizadosAdministradorService,
  buscarVentaPorAdministradorMesAnioService,
} from "../../../service/administrador/buscarVentas";
import {
  mensajeError,
  mensajeErrorBloqueo,
  mensajeErrorNoAutorizado,
  mensajeErrorPersonalizado,
} from "../../../helpers/mensajesSwalError";
import CargaMedia from "../../../componentes/cargando/CargandoMedio";
import Carga from "../../../componentes/cargando/Cargando";
import { formatearAPesos, formatoFecha } from "../../../helpers/formatos.js";
import { useNavigate } from "react-router-dom";

const obtenerMesActual = () => {
  const fechaActual = new Date();
  const mesActual = fechaActual.getMonth() + 1;
  return mesActual;
};

const obtenerAnioActual = () => {
  return new Date().getFullYear();
};

const obtenerDiasDelMes = (m, a) => {
  return new Date(a, m, 0).getDate();
};

const generarListaDias = (mes, anio) => {
  const cantidadDias = obtenerDiasDelMes(mes, anio);
  return Array.from({ length: cantidadDias }, (_, i) => i + 1);
};

const obtenerDiaSemana = (dia) => {
  const fecha = new Date(new Date().getFullYear(), new Date().getMonth(), dia);
  const diasDeLaSemana = [
    "Domingo",
    "Lunes",
    "Martes",
    "Miércoles",
    "Jueves",
    "Viernes",
    "Sábado",
  ];
  return diasDeLaSemana[fecha.getDay()];
};

const VentaDiaria = () => {
  const { verificacionToken } = useAuth();
  const navigate = useNavigate();
  const [cantidadDia, setCantidadDia] = useState(
    generarListaDias(obtenerMesActual(), obtenerAnioActual())
  );
  const [mes, setMes] = useState(obtenerMesActual());
  const [anio, setAnio] = useState(obtenerAnioActual());
  const [anion, setAnion] = useState([]);
  const [cargandoVentas, setCargandoVentas] = useState(true);
  const [cargandoVentasPorAnio, SetCargandoVentasPorAnio] = useState(true);
  const [ventasAdministrador, setVentasAdministrador] = useState([]);
  const [ montoMensual, setMontoMensual ] = useState(0)

  useEffect(() => {
    verificacionToken("ROLE_ADMINISTRADOR");
    buscarAnioVentaAdministrador();
    buscarVentasPorMesAdministrador(obtenerMesActual(), obtenerAnioActual());
  }, []);

  const filtrarMes = (e) => {
    e.preventDefault();
    buscarVentasPorMesAdministrador(mes, anio);
    setCantidadDia(generarListaDias(mes, anio));
  };

  const buscarAnioVentaAdministrador = async () => {
    const respuesta = await buscarAniosRealizadosAdministradorService();
    if (respuesta[0]) {
      setAnion(respuesta[1]);
    } else {
      if (respuesta[1] === 400) {
        mensajeErrorPersonalizado(respuesta[2]);
      } else if (respuesta[1] === 401) {
        mensajeErrorNoAutorizado();
      } else if (respuesta[1] === 403) {
        mensajeErrorBloqueo();
      } else {
        mensajeError();
      }
    }
    SetCargandoVentasPorAnio(false);
  };

  const buscarVentasPorMesAdministrador = async (mes, anio) => {
    setCargandoVentas(true);
    const respuesta = await buscarVentaPorAdministradorMesAnioService(
      mes,
      anio
    );
    if (respuesta[0]) {
      setVentasAdministrador(respuesta[1]);
      setMontoMensual(respuesta[1].reduce((acumulador, valorActual) => acumulador + Number(valorActual.venta_total), 0))
    } else {
      if (respuesta[1] === 400) {
        mensajeErrorPersonalizado(respuesta[2]);
      } else if (respuesta[1] === 401) {
        mensajeErrorNoAutorizado();
      } else if (respuesta[1] === 403) {
        mensajeErrorBloqueo();
      } else {
        mensajeError();
      }
    }
    setCargandoVentas(false);
  };

  const mostrarDetalles = (id) => {
    navigate(`/administrador/ventas-diarias/${id}`);
  }

  return (
    <div className="container mt-4 pb-5">
      <h2 className="text-center">Mis Ventas Mesuales</h2>
      <div className="row mb-3 justify-content-center py-5">
        <form onSubmit={filtrarMes} className="row mb-3">
          {cargandoVentasPorAnio ? (
            <CargaMedia />
          ) : (
            <>
              <div className="col-md-4">
                <label htmlFor="filtroMes" className="form-label">
                  Seleccionar Mes
                </label>
                <select
                  id="filtroMes"
                  className="form-select"
                  value={mes}
                  onChange={(e) => setMes(e.target.value)}
                >
                  <option value="1">Enero</option>
                  <option value="2">Febrero</option>
                  <option value="3">Marzo</option>
                  <option value="4">Abril</option>
                  <option value="5">Mayo</option>
                  <option value="6">Junio</option>
                  <option value="7">Julio</option>
                  <option value="8">Agosto</option>
                  <option value="9">Septiembre</option>
                  <option value="10">Octubre</option>
                  <option value="11">Noviembre</option>
                  <option value="12">Diciembre</option>
                </select>
              </div>
              <div className="col-md-4">
                <label htmlFor="filtroAnio" className="form-label">
                  Seleccionar Año
                </label>
                <select
                  id="filtroAnio"
                  className="form-select"
                  value={anio}
                  onChange={(e) => setAnio(e.target.value)}
                >
                  {anion.map((a) => (
                    <option key={a.id} value={a}>
                      {a}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-4 d-flex align-items-end">
                <button type="submit" className="btn btn-outline-success me-2">
                  Aplicar Filtros
                </button>
              </div>
            </>
          )}
          <div className="py-4"></div>
          {cargandoVentas ? (
            <Carga />
          ) : ventasAdministrador.length === 0 ? (
            <div className="alert alert-danger">No se encontro ventas del Mes</div>
          ) : (<>
            <table className="table table-striped table-hover">
              <thead>
                <tr>
                  <th>Local</th>
                  <th>Venta del Día</th>
                  <th>Meta del Día</th>
                  <th>Fecha</th>
                  <th>Detalle</th>
                </tr>
              </thead>
              <tbody>
                {ventasAdministrador.map((venta) => (
                  <tr key={venta.id}>
                    <td>{venta.nombreLocal}</td>
                    <td
                      className={
                        Number(venta.venta_total) < Number(venta.kpi)
                          ? "text-danger"
                          : "text-primary"
                      }
                    >
                      {formatearAPesos(venta.venta_total)}
                    </td>
                    <td className="text-success">
                      {formatearAPesos(venta.kpi)}
                    </td>
                    <td>{formatoFecha(venta.createdAt)}</td>
                    <td>
                      <button 
                      className="btn btn-outline-info"
                      onClick={() => mostrarDetalles(venta.id)}
                      >
                        Detalle
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="py-1">
              <div className="align-content-lg-center">
                <h2 className="fw-bold">Venta Mensual: {formatearAPesos(montoMensual)}</h2>
                </div>
            </div>
            </>)}
        </form>
      </div>
    </div>
  );
};
export default VentaDiaria;
