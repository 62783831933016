import React, { useEffect, useState } from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Table,
  Button,
  Modal,
  Form,
  Alert,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../AuthContext.js";
import {
  buscarEmpleados,
  buscarLocalAdmin,
  bloquearUsuario,
  habilitarUsuario,
  buscarServiciosApi,
} from "../../../service/ObtenerLocalesAdmin.js";
import {
  mensajeError,
  mensajeErrorNoAutorizado,
  mensajeErrorSesion,
} from "../../../helpers/mensajesSwalError.js";
import CargandoComponente from "../../../componentes/cargando/CargandoComponentes.js";
import Carga from "../../../componentes/cargando/Cargando.js";
import CargaMedia from "../../../componentes/cargando/CargandoMedio.js";
import { buscarEmpleadosPorIdService } from "../../../service/dueno/buscarEmpleados.js";

const ListaEmpleados = () => {
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [locales, setLocales] = useState([]);
  const [localSeleccionado, setLocalSeleccionado] = useState("");
  const { verificacionToken, removerLocalStorage } = useAuth();
  const [cargandoUsuario, setCargandoUsuario] = useState(true);
  const [cargandoLocal, setCargandoLocal] = useState(true);

  const [nuevoEmpleadoEmail, setNuevoEmpleadoEmail] = useState("");
  const [nuevoEmpleadoNombre, setNuevoEmpleadoNombre] = useState("");
  const [nuevoEmpleadoApellido, setNuevoEmpleadoApellido] = useState("");
  const [nuevoEmpleadoRut, setNuevoEmpleadoRut] = useState("");
  const [nuevoEmpleadoNumero, setNuevoEmpleadoNumero] = useState("");
  const [servicios, setServicios] = useState([]);
  const [cargandoServicios, setCargandoServicios] = useState(true);

  const [showModalEditar, setShowModalEditar] = useState(false);
  const [cargandoEditarEmpleados, setCargandoEditarEmpleados] = useState(true);
  const [editarEmpleadoNombre, setEditarEmpleadoNombre] = useState("");
  const [editarEmpleadoApellido, setEditarEmpleadoApellido] = useState("");
  const [editarEmpleadoNumero, setEditarEmpleadoNumero] = useState("");
  const [editarLocalSeleccionado, setEditarLocalSeleccionado] = useState("");
  const [editarEmpleadoId, setEditarEmpleadoId] = useState(0)

  const navigate = useNavigate();

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const handleNavigate = () => {
    handleClose();
  };

  useEffect(() => {
    //verificacion del token
    verificacionToken("ROLE_DUENO");
    buscarLocal();
    buscarEmpleado();
    buscarServicios();
    setLoading(false);
  }, []);

  //buscarServicio
  const buscarServicios = async () => {
    const respuestasServicios = await buscarServiciosApi();
    if (respuestasServicios[0]) {
      if (respuestasServicios[1].length === 0) {
        setServicios([]);
      } else {
        setServicios(
          respuestasServicios[1].map((ser) => {
            return {
              id: ser.id,
              servicio: ser.servicio,
              comision: ser.comision,
              estado: false,
            };
          })
        );
      }
    } else {
      setServicios([]);
    }
    setCargandoServicios(false);
  };

  //buscar empleados
  const buscarEmpleado = async () => {
    const respuestaEmpleados = await buscarEmpleados();

    if (!respuestaEmpleados[0]) {
      if (respuestaEmpleados[3] === 401) {
        removerLocalStorage();
        mensajeErrorSesion();
      } else if (respuestaEmpleados[3] === 403) {
        removerLocalStorage();
        mensajeErrorNoAutorizado();
      } else {
        removerLocalStorage();
        mensajeError();
      }
    } else {
      setEmployees(respuestaEmpleados[1]);
      setCargandoUsuario(false);
    }
  };

  //buscar locales
  const buscarLocal = async () => {
    const respuestaLocal = await buscarLocalAdmin();

    if (!respuestaLocal[0]) {
      if (respuestaLocal[2] === 401) {
        removerLocalStorage();
        mensajeErrorSesion();
      } else if (respuestaLocal[2] === 403) {
        removerLocalStorage();
        mensajeErrorNoAutorizado();
      } else {
        removerLocalStorage();
        mensajeError();
      }
    } else {
      setLocales(respuestaLocal[1]);
      setCargandoLocal(false);
    }
  };

  const verVentasEmpleados = async (id) => {
    console.log(id);
  };

  const handleBlock = async (id) => {
    const bloquearUsuarioHandler = await bloquearUsuario(id);
    if (bloquearUsuarioHandler[0]) {
      setEmployees(
        employees.map((emp) =>
          emp.id === id ? { ...emp, enabled: false } : emp
        )
      );
    }
  };

  const handleUnblock = async (id) => {
    const habilitarUsuarioHandler = await habilitarUsuario(id);
    if (habilitarUsuarioHandler[0]) {
      setEmployees(
        employees.map((emp) => (emp.id === id ? { ...emp, enabled: true } : emp))
      );
    }
  };

  const editarEmpleado = async () => {
    Swal.fire({
      title: "Quieres editar el Empleado?",
      showCancelButton: true,
      confirmButtonText: "Editar",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Editando empleado...",
          text: "Por favor, espere un momento.",
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });
        const add = async () => {
          const campos = [
            { valor: editarEmpleadoNombre, nombre: "Nombre" },
            { valor: editarEmpleadoApellido, nombre: "Apellido" },
            { valor: editarEmpleadoNumero, nombre: "Número" },
          ];

          for (let campo of campos) {
            if (campo.valor.trim() === "") {
              return Swal.fire({
                title: "Error",
                text: `No puede estar vacío el campo ${campo.nombre}`,
                icon: "error",
              });
            }
          }

          if (editarLocalSeleccionado == 0) {
            return Swal.fire({
              title: "Error",
              text: `Seleccione un local`,
              icon: "error",
            });
          }

          const token = localStorage.getItem("token");
          const config = {
            headers: {
              Authorization: `${token}`,
            },
          };
          const data = {
                nombre: editarEmpleadoNombre,
                apellido: editarEmpleadoApellido,
                numero: editarEmpleadoNumero,
                local_id: Number(editarLocalSeleccionado),
                servicios,
          }
          try {
            await axios.put(
              `${process.env.REACT_APP_API_URL}/administrador/empleados/agregar/empleado/${editarEmpleadoId}`,
              data,
              config
            );  
            await buscarEmpleado();
            setShowModalEditar(false);
            setCargandoEditarEmpleados(true)
            restablecerCheck();
            return Swal.fire({
              title: "Actualizado!",
              text: "Se ha cambiado con exito el empleado",
              icon: "success",
            });
          } catch (error) {
            if (error.status === 406) {
              return Swal.fire({
                title: "Error",
                text: "El correo o el rut ya estan registrado",
                icon: "error",
              });
            } else if (error.status === 403) {
              navigate("/no-autorizado");
            } else {
              return Swal.fire({
                title: "Error",
                text: "Ocurrió un problema al agregar el empleado",
                icon: "error",
              });
            }
          }
        };
        add();
      }
    });
  }

  const handleAddEmployee = async () => {
    Swal.fire({
      title: "Quieres agregar un Empleado?",
      showCancelButton: true,
      confirmButtonText: "Agregar",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Agregando empleado...",
          text: "Por favor, espere un momento.",
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });
        const add = async () => {
          const campos = [
            { valor: nuevoEmpleadoEmail, nombre: "Email" },
            { valor: nuevoEmpleadoRut, nombre: "Rut" },
            { valor: nuevoEmpleadoNombre, nombre: "Nombre" },
            { valor: nuevoEmpleadoApellido, nombre: "Apellido" },
            { valor: nuevoEmpleadoNumero, nombre: "Número" },
          ];

          for (let campo of campos) {
            if (campo.valor.trim() === "") {
              return Swal.fire({
                title: "Error",
                text: `No puede estar vacío el campo ${campo.nombre}`,
                icon: "error",
              });
            }
          }

          if (localSeleccionado == 0) {
            return Swal.fire({
              title: "Error",
              text: `Seleccione un local`,
              icon: "error",
            });
          }

          const token = localStorage.getItem("token");
          const config = {
            headers: {
              Authorization: `${token}`,
            },
          };
          try {
            await axios.post(
              `${process.env.REACT_APP_API_URL}/administrador/empleados/agregar/empleado`,
              {
                email: nuevoEmpleadoEmail,
                rut: nuevoEmpleadoRut,
                nombre: nuevoEmpleadoNombre,
                apellido: nuevoEmpleadoApellido,
                numero: nuevoEmpleadoNumero,
                local_id: localSeleccionado,
                servicios,
              },
              config
            );
            await buscarEmpleado();
            setShowModal(false);
            setNuevoEmpleadoEmail("");
            restablecerCheck();
            return Swal.fire({
              title: "Agregado!",
              text: "Se ha agregado con exito el empleado",
              icon: "success",
            });
          } catch (error) {
            if (error.status === 406) {
              return Swal.fire({
                title: "Error",
                text: "El correo o el rut ya estan registrado",
                icon: "error",
              });
            } else if (error.status === 403) {
              navigate("/no-autorizado");
            } else {
              return Swal.fire({
                title: "Error",
                text: "Ocurrió un problema al agregar el empleado",
                icon: "error",
              });
            }
          }
        };
        add();
      }
    });
  };

  if (loading) {
    return <Carga />;
  }

  const hadlerBuscarUsuario = (id) => {
    buscarEmpleadosPorId(id);
    setShowModalEditar(true);
  };

  const cerrarHadlerEditar = () => {
    setShowModalEditar(false)
    setCargandoEditarEmpleados(true)
  }

  const buscarEmpleadosPorId = async (id) => {
    const respuesta = await buscarEmpleadosPorIdService(id);

    if (respuesta[0]) {
      setEditarEmpleadoNombre(respuesta[1].perfil.nombres);
      setEditarEmpleadoApellido(respuesta[1].perfil.apellidos);
      setEditarEmpleadoNumero(respuesta[1].perfil.numero);
      setServicios(
        servicios.map((ser) => {
          if (respuesta[1].servicios.length > 0) {
            const servicioEncontrado = respuesta[1].servicios.find(
              (ser1) => ser.id === ser1.id
            );
            return {
              id: ser.id,
              servicio: ser.servicio,
              comision: ser.comision,
              estado: !!servicioEncontrado,
            };
          } else {
            return {
              id: ser.id,
              servicio: ser.servicio,
              comision: ser.comision,
              estado: false,
            };
          }
        })
      );
      setEditarLocalSeleccionado(respuesta[1].local_id);
      setEditarEmpleadoId(respuesta[1].id)
    }
    setCargandoEditarEmpleados(false);
  };

  const cambiarCheck = (id) => {
    setServicios(
      servicios.map((ser) => {
        if (ser.id === id) {
          return { ...ser, estado: !ser.estado };
        }
        return ser;
      })
    );
  };

  const restablecerCheck = () => {
    setServicios(
      servicios.map((ser) => {
        return { ...ser, estado: false };
      })
    );
  };

  return (
    <div className="container mt-5 pb-5">
      <div className="row align-items-center mb-3">
        <h2 className="col-8">Lista de Empleados</h2>
        {cargandoLocal ? (
          <CargandoComponente />
        ) : (
          <Button
            className="col-auto"
            variant="btn btn-outline-primary"
            onClick={() => setShowModal(true)}
          >
            Agregar Empleado
          </Button>
        )}
      </div>

      {cargandoUsuario ? (
        <div className="mt-5 d-flex justify-content-center">
          <CargandoComponente />
        </div>
      ) : (
        <div>
          {employees.length === 0 ? (
            <Container>
              <Row className="justify-content-center">
                <Col xs={12} sm={10} md={8} lg={6}>
                  <Alert variant="warning" className="text-center mt-4">
                    <Alert.Heading>¡Atención!</Alert.Heading>
                    <p>No hay empleados disponibles en este momento.</p>
                  </Alert>
                </Col>
              </Row>
            </Container>
          ) : (
            <Table striped bordered hover className="mt-3">
              <thead>
                <tr>
                  <th>Ventas</th>
                  <th>Nombres</th>
                  <th>Local</th>
                  <th>Acción</th>
                </tr>
              </thead>
              <tbody>
                {employees.map((employee) => (
                  <tr key={employee.id}>
                    <td>
                      <Button
                        variant="btn btn-outline-dark"
                        onClick={() => verVentasEmpleados(employee.id)}
                      >
                        Ver Ventas
                      </Button>
                    </td>
                    <td>
                      {employee.perfil.nombres} {employee.perfil.apellidos}
                    </td>
                    <td>{employee.local.nombres}</td>
                    <td>
                      {employee.enabled === true ? (
                        <Button
                          variant="btn btn-outline-danger"
                          onClick={() => handleBlock(employee.id)}
                        >
                          Bloquear
                        </Button>
                      ) : (
                        <Button
                          variant="btn btn-outline-success"
                          onClick={() => handleUnblock(employee.id)}
                        >
                          Habilitar
                        </Button>
                      )}
                      <Button
                        className="m-lg-1"
                        variant="btn btn-outline-info"
                        onClick={() => hadlerBuscarUsuario(employee.id)}
                      >
                        Editar
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </div>
      )}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Agregar Nuevo Empleado</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formEmployeeName">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Ingrese un email"
                value={nuevoEmpleadoEmail}
                onChange={(e) => setNuevoEmpleadoEmail(e.target.value)}
              />
              <Form.Label>Rut</Form.Label>
              <Form.Control
                type="text"
                placeholder="Ingrese un rut o indentificador"
                value={nuevoEmpleadoRut}
                onChange={(e) => setNuevoEmpleadoRut(e.target.value)}
              />
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                type="text"
                placeholder="Ingrese un nombre"
                value={nuevoEmpleadoNombre}
                onChange={(e) => setNuevoEmpleadoNombre(e.target.value)}
              />
              <Form.Label>Apellido</Form.Label>
              <Form.Control
                type="text"
                placeholder="Ingrese un apellido"
                value={nuevoEmpleadoApellido}
                onChange={(e) => setNuevoEmpleadoApellido(e.target.value)}
              />
              <Form.Label>Teléfono</Form.Label>
              <Form.Control
                type="text"
                placeholder="Ingrese un número"
                value={nuevoEmpleadoNumero}
                onChange={(e) => setNuevoEmpleadoNumero(e.target.value)}
              />
              <Form.Label>Local</Form.Label>
              <Form.Control
                as="select"
                value={localSeleccionado}
                onChange={(e) => setLocalSeleccionado(e.target.value)}
              >
                <option value="">Seleccione un local</option>
                {locales.map((local) => (
                  <option key={local.id} value={local.id}>
                    {local.nombres}
                  </option>
                ))}
              </Form.Control>
              <Form.Label>Servicios</Form.Label>
              {cargandoServicios ? (
                <CargaMedia />
              ) : (
                servicios.map((ser) => (
                  <Form.Check
                    key={ser.id}
                    type="checkbox"
                    id={ser.id}
                    label={ser.servicio}
                    onChange={() => cambiarCheck(ser.id)}
                  />
                ))
              )}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="btn btn-outline-success" onClick={handleAddEmployee}>
            Agregar
          </Button>
          <Button
            variant="btn btn-outline-danger"
            onClick={() => setShowModal(false)}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showModalEditar} onHide={() => cerrarHadlerEditar()}>
        <Modal.Header closeButton>
          <Modal.Title>Editar Empleado</Modal.Title>
        </Modal.Header>
        {cargandoEditarEmpleados ? (
          <div className="py-4"><CargaMedia/></div>
        ) : (
          <>
            <Modal.Body>
              <Form>
                <Form.Group controlId="formEmployeeName">
                  <Form.Label>Nombre</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Ingrese un nombre"
                    value={editarEmpleadoNombre}
                    onChange={(e) => setEditarEmpleadoNombre(e.target.value)}
                  />
                  <Form.Label>Apellido</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Ingrese un apellido"
                    value={editarEmpleadoApellido}
                    onChange={(e) => setEditarEmpleadoApellido(e.target.value)}
                  />
                  <Form.Label>Teléfono</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Ingrese un número"
                    value={editarEmpleadoNumero}
                    onChange={(e) => setEditarEmpleadoNumero(e.target.value)}
                  />
                  <Form.Label>Local</Form.Label>
                  <Form.Control
                    as="select"
                    value={editarLocalSeleccionado}
                    onChange={(e) => setEditarLocalSeleccionado(e.target.value)}
                  >
                    {locales.map((local) => (
                      <option key={local.id} value={local.id}>
                        {local.nombres}
                      </option>
                    ))}
                  </Form.Control>
                  <Form.Label>Servicios</Form.Label>
                  {servicios.map((ser) => (
                    <Form.Check
                      key={ser.id}
                      type="checkbox"
                      id={ser.id}
                      label={ser.servicio}
                      checked={ser.estado}
                      onChange={() => cambiarCheck(ser.id)}
                    />
                  ))}
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="btn btn-outline-success"
                onClick={editarEmpleado}
              >
                Editar
              </Button>
              <Button
                variant="btn btn-outline-danger"
                onClick={() => cerrarHadlerEditar()}
              >
                Cancel
              </Button>
            </Modal.Footer>
          </>
        )}
      </Modal>
    </div>
  );
};

export default ListaEmpleados;
