import axios from "axios";
import { formatoFechaBD } from "../../helpers/formatos";

const buscarVentasPorDueno = async (page, fechaInicio, fechaFin) => {
  const nuevaFechaInicio = `${formatoFechaBD(fechaInicio)}T00:00:00`;
  const nuevaFechaFin = `${formatoFechaBD(fechaFin)}T23:59:59`;
  try {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `${token}`,
      },
      params: {
        page: page,
        limit: 10,
        fechaInicio: nuevaFechaInicio,
        fechaFin: nuevaFechaFin,
      },
    };

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/dueno/buscar-ventas-empleados`,
      config
    );
    return [
      response.data.ventas,
      response.data.totalVentas,
      response.data.totalPages,
      response.data.currentPage,
    ];
  } catch (error) {
    return null;
  }
};

const buscarVentasPorDuenoNuevo = async (mes, anio) => {
  const token = localStorage.getItem("token");
  const config = {
    headers: {
      Authorization: `${token}`,
    }
  };
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/dueno/buscar-ventas-empleados/${mes}/${anio}`,
      config
    );
    return [true, response.data];
  } catch (error) {
    return [false, error.status, error.response.data];
  }
};

const buscarAniosRealizados = async () => {
  const token = localStorage.getItem("token");
  const config = {
    headers: {
      Authorization: `${token}`,
    },
  };
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/dueno/buscar-ventas-por-anion`,
      config
    );
    return [true, response.data];
  } catch (error) {
    return [false, error.status, error.response.data];
  }
};

const buscarLocalesDueno = async () => {
  const token = localStorage.getItem("token");
  const config = {
    headers: {
      Authorization: `${token}`,
    },
  };
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/dueno/buscar-locales`,
      config
    );
    return [true, response.data];
  } catch (error) {
    return [false, error.status, error.response.data];
  }
}

const buscarVentasDuenoPorId = async (id) => {
  const token = localStorage.getItem("token");
  const config = {
    headers: {
      Authorization: `${token}`,
    },
  };
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/dueno/buscar-ventas-por-id/${id}`,
      config
    );
    return [true, response.data.ventaEmpleados, response.data.pagos, response.data.descuentos, response.data.nuevaVenta, response.data.local];
  } catch (error) {
    return [false, error.status, error.response.data];
  }
}

const buscarAdministradorDisponiblePorLocales = async (id) => {
  const token = localStorage.getItem("token");
  const config = {
    headers: {
      Authorization: `${token}`,
    },
  };
  try {
    await axios.get(
      `${process.env.REACT_APP_API_URL}/dueno/buscar-administrador-por-local/${id}`,
      config
    );
    return true;
  } catch (error) {
    return false;
  }
}

export { buscarVentasPorDueno, buscarAniosRealizados, buscarVentasPorDuenoNuevo, buscarLocalesDueno, buscarVentasDuenoPorId, buscarAdministradorDisponiblePorLocales };
