import React, { createContext, useState, useContext } from "react";
import { obtenerRespuestaSesionRol } from "./service/ObtenerRespuestaSesion.js";
import Swal from "sweetalert2";
import {
  mensajeError,
  mensajeErrorNoAutorizado,
  mensajeErrorSesion,
} from "./helpers/mensajesSwalError.js";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    const token = localStorage.getItem('token')
    return token?true:false
  })
  const [user, setUser] = useState(null);

  const verificacionToken = (rol) => {
      const getSesion = async () => {
        const statusRol = await obtenerRespuestaSesionRol(rol);
        if (!statusRol[1]) {
          window.location.href = '/no-encontrado';
          switch(statusRol[0]){
            case 400: removerLocalStorage()
                      mensajeError()
                      break
            case 401: removerLocalStorage()
                      mensajeErrorSesion()
                      break
            case 403: removerLocalStorage()
                      mensajeErrorNoAutorizado()
                      break
            case 400: removerLocalStorage()
                      mensajeError()
                      break
          }
        }
      };
      getSesion();
  }; 

  const removerLocalStorage = () => {
    setIsAuthenticated(false);
    localStorage.removeItem("token");
    localStorage.removeItem("usuario_id");
    localStorage.removeItem("rol");
  };

  const login = (userData) => {
    setIsAuthenticated(true);
    setUser(userData);
    localStorage.setItem("token", userData.token);
    localStorage.setItem("usuario_id", userData.usuario_id);
    localStorage.setItem("rol", userData.rol);
  };

  const forceLogout = () => {
    setIsAuthenticated(false);
    setUser(null);
    localStorage.removeItem("token");
    localStorage.removeItem("usuario_id");
    localStorage.removeItem("rol");
  };

  const logout = () => {
    const borrarDatos = () => {
      setIsAuthenticated(false);
      setUser(null);
      localStorage.removeItem("token");
      localStorage.removeItem("usuario_id");
      localStorage.removeItem("rol");
      window.location.href = "/";
    };

    return Swal.fire({
      title: "Cerrar Sesión",
      text: "Estas segura/o de cerrar sesión?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Cerrar!",
    }).then((result) => {
      if (result.isConfirmed) {
        borrarDatos();
      }
    });
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user,
        login,
        logout,
        forceLogout,
        setIsAuthenticated,
        verificacionToken,
        removerLocalStorage
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
