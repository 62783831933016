import { useEffect, useState } from "react";
import Carga from "../../../componentes/cargando/Cargando";
import { useAuth } from "../../../AuthContext";
import { useParams } from "react-router-dom";
import { buscarVentasDuenoPorId } from "../../../service/dueno/buscarVentas";
import {
  mensajeError,
  mensajeErrorBloqueo,
  mensajeErrorNoAutorizado,
  mensajeErrorPersonalizado,
} from "../../../helpers/mensajesSwalError";
import { formatearAPesos, formatoFecha } from "../../../helpers/formatos";
import { useNavigate } from "react-router-dom";
import { Button, Form, Modal, Table } from "react-bootstrap";
import { actualizarVentaServicio } from "../../../service/dueno/actualizarVenta";
import { mesanjeExitoPersonalizado } from "../../../helpers/mensajesSwalExito";

const VerVenta = () => {
  const [cargando, setCargando] = useState(true);
  const { verificacionToken } = useAuth();
  const [ventasEmpleados, setVentasEmpleados] = useState([]);
  const [metodosPagos, setMetodosPagos] = useState([]);
  const [descuentos, setDescuentos] = useState([]);
  const [venta, setVenta] = useState([]);
  const [local, setLocal] = useState([]);
  const { id } = useParams();
  let totalVentaEmpleados = 0;
  let totalVentaMetodos = 0;
  const navigate = useNavigate();

  const [verDetalles, setVerDetalles] = useState(false);
  const [detalles, setDetalles] = useState([]);
  const [nombreEmpleado, setNombreEmpleado] = useState("");

  //editar ventas
  const [editarVenta, setEditarVenta] = useState(false);
  const [editarVerDetalles, setEditarVerDetalles] = useState(false);
  const [editarDetalles, setEditarDetalles] = useState([]);
  const [editarVentasEmpleados, setEditarVentasEmpleados] = useState([]);
  const [editarMetodosPagos, setEditarMetodosPagos] = useState([]);
  const [editarDescuentos, setEditarDescuentos] = useState([]);
  const [editarTotalVentasEmpleados, setEditarTotalVentasEmpleados] =
    useState(0);
  const [editarTotalVentasMetodosPagos, setEditarTotalVentasMetodosPagos] =
    useState(0);

  useEffect(() => {
    verificacionToken("ROLE_DUENO");
    buscarVentaPorId(id);
  }, []);

  const buscarVentaPorId = async (id) => {
    const respuesta = await buscarVentasDuenoPorId(id);

    if (respuesta[0]) {
      setVentasEmpleados(respuesta[1]);
      setMetodosPagos(respuesta[2]);
      setDescuentos(respuesta[3]);
      setEditarVentasEmpleados(respuesta[1]);
      setEditarMetodosPagos(respuesta[2]);
      setEditarDescuentos(respuesta[3]);
      setEditarTotalVentasEmpleados(
        respuesta[1].reduce(
          (acumulado, venta) => acumulado + Number(venta.monto_total),
          0
        )
      );
      setEditarTotalVentasMetodosPagos(
        respuesta[2].reduce(
          (acumulado, venta) => acumulado + Number(venta.total),
          0
        )
      );
      setVenta(respuesta[4]);
      setLocal(respuesta[5]);
    } else {
      if (respuesta[1] === 400) {
        mensajeErrorPersonalizado(respuesta[2]);
      } else if (respuesta[1] === 401) {
        mensajeErrorNoAutorizado();
      } else if (respuesta[1] === 403) {
        mensajeErrorBloqueo();
      } else {
        mensajeError();
      }
      navigate("/dueno/ventas-empleados");
    }
    setCargando(false);
  };

  const verDetallesEmpleado = (servicios, nombre) => {
    setVerDetalles(true);
    setNombreEmpleado(nombre);
    setDetalles(servicios);
  };

  const cerrarDetallesVenta = () => {
    setVerDetalles(false);
    setNombreEmpleado("");
    setDetalles([]);
  };

  const editarVentasHandler = () => {
    setEditarVenta(true);
  };

  const NoteditarVentasHandler = () => {
    setEditarVenta(false);
  };

  const editarVerDetallesVenta = (servicios, nombre) => {
    setEditarVerDetalles(true);
    setNombreEmpleado(nombre);
    setEditarDetalles(servicios);
  };

  const editarCerrarDetallesVenta = () => {
    setEditarVerDetalles(false);
    setEditarDetalles([]);
    setNombreEmpleado("");
  };

  const restablecerValores = () => {
    setEditarVentasEmpleados(ventasEmpleados);
    setEditarMetodosPagos(metodosPagos);
    setEditarDescuentos(descuentos);
    setEditarTotalVentasEmpleados(
      ventasEmpleados.reduce(
        (acumulado, venta) => acumulado + Number(venta.monto_total),
        0
      )
    );
    setEditarTotalVentasMetodosPagos(
      metodosPagos.reduce(
        (acumulado, venta) => acumulado + Number(venta.total),
        0
      )
    );
  }

  const editarActualizarValorMetodoPago = (id, event) => {
    const valorInput = event.target.value;
    const valorNumerico = Number(valorInput.replace(/\D/g, "")) || 0;
    setEditarMetodosPagos((pagos) => {
      const valores = pagos.map((p) => {
        if (id === p.id) {
          return {
            ...p,
            total: String(valorNumerico),
          };
        } else {
          return {
            ...p,
          };
        }
      });
      return valores;
    });
  };

  const editarActualizarCantidadCliente = (id, event) => {
    const valorInput = event.target.value;
    const valorNumerico = Number(valorInput.replace(/\D/g, "")) || 0;
    setEditarVentasEmpleados((detalle) => {
      const valores = detalle.map((d) => {
        if (id === d.id) {
          return {
            ...d,
            cantidadCliente: String(valorNumerico),
          };
        } else {
          return {
            ...d,
          };
        }
      });
      return valores;
    });
  };

  const editarActualizarValorDetalles = (event, servicio_id, usuario_id) => {
    const valorInput = event.target.value;
    const valorNumerico = Number(valorInput.replace(/\D/g, "")) || 0;
    setEditarVentasEmpleados((nuevosDetalles) => {
      const valores = nuevosDetalles.map((detalle) => {
        if (detalle.usuario_id === usuario_id) {
          return {
            ...detalle,
            servicios: detalle.servicios.map((servicio) => {
              if (servicio.servicio_id === servicio_id) {
                return {
                  ...servicio,
                  venta_total: String(valorNumerico),
                };
              }
              return servicio;
            }),
          };
        }
        return detalle;
      });

      const nuevosValores = valores.map((valor) => {
        if (valor.servicios.length === 0) {
          return {
            ...valor,
          };
        }

        const valorTotal = valor.servicios.reduce(
          (acumulado, valor) => Number(acumulado) + Number(valor.venta_total),
          0
        );
        return {
          ...valor,
          monto_total: valorTotal,
        };
      });
      const nuevoMontoTotal = nuevosValores.reduce(
        (acumulado, venta) => Number(acumulado) + Number(venta.monto_total),
        0
      );
      setEditarTotalVentasEmpleados(nuevoMontoTotal);
      return nuevosValores;
    });
  };

  const editarActualizarDescuento = (event) => {
    const valorInput = event.target.value;
    const valorNumerico = Number(valorInput.replace(/\D/g, "")) || 0;
    setEditarDescuentos((des) => {
      return {
        ...des,
        descuento: String(valorNumerico),
      };
    });
  };

  const editarComentarios = (valor) => {
    setEditarDescuentos((des) => {
      return {
        ...des,
        comentario: String(valor),
      };
    });
  }

  const actualizarVentaHandler = async () => {
    const montoTotalVentas = editarVentasEmpleados.reduce(
      (acumulado, venta) =>  Number(acumulado) + Number(venta.monto_total) , 0)
    const montoTotalMetodos = editarMetodosPagos.reduce(
      (acumulado, venta) => Number(acumulado) + Number(venta.total), 0)
    const montoTotalDescuento = Number(editarDescuentos.descuento)
    if(montoTotalDescuento === 0){
      if(montoTotalVentas === montoTotalMetodos){
        const respuestaMensaje = await actualizarVentaServicio(editarVentasEmpleados,
          editarMetodosPagos,
          editarDescuentos
        )
        if (respuestaMensaje[0]) {
          mesanjeExitoPersonalizado("Se actualizo correctamente la venta...")
          setEditarVenta(false)
          await buscarVentaPorId(respuestaMensaje[1])
        } else {
          if (respuestaMensaje[2] === 400) {
            mensajeErrorPersonalizado("Error al actualizar la venta");
          } else if (respuestaMensaje[2] === 401) {
            mensajeErrorNoAutorizado();
          } else if (respuestaMensaje[2] === 403) {
            mensajeErrorBloqueo();
          } else {
            mensajeError();
          }
          navigate(`/dueno/ventas-empleados/${respuestaMensaje[1]}`);
        }

      }else{
        mensajeErrorPersonalizado("Los valores no coinciden")
      }
    }else{
      if((montoTotalMetodos + montoTotalDescuento) === montoTotalVentas){
        const respuestaMensaje = await actualizarVentaServicio(editarVentasEmpleados,
          editarMetodosPagos,
          editarDescuentos
        )
        if (respuestaMensaje[0]) {
          mesanjeExitoPersonalizado("Se actualizo correctamente la venta...")
          setEditarVenta(false)
          await buscarVentaPorId(respuestaMensaje[1])
        } else {
          if (respuestaMensaje[2] === 400) {
            mensajeErrorPersonalizado("Error al actualizar la venta");
          } else if (respuestaMensaje[2] === 401) {
            mensajeErrorNoAutorizado();
          } else if (respuestaMensaje[2] === 403) {
            mensajeErrorBloqueo();
          } else {
            mensajeError();
          }
          navigate(`/dueno/ventas-empleados/${respuestaMensaje[1]}`);
        }
      }else{
        mensajeErrorPersonalizado("Los valores no coinciden")
      }
    }
  };

  return (
    <div className="pb-5">
      {cargando ? (
        <div>
          <Carga />
        </div>
      ) : (
        <div>
          {cargando ? (
            <Carga />
          ) : (
            <div className="container">
              <div className="row my-3">
                <div className="col-12 col-md-6 d-flex justify-content-end">
                {editarVenta && (
                    <button
                    className="btn btn-outline-warning btn-lg me-2 w-100 w-md-auto"
                    onClick={() => restablecerValores()}
                  >
                    Restablecer Valor
                  </button>
                  )}
                  <button
                    className="btn btn-outline-primary btn-lg w-100 w-md-auto"
                    onClick={() => navigate(-1)}
                  >
                    Volver atrás
                  </button>
                </div>
                <div className="col-12 col-md-6 d-flex justify-content-end">
                  {editarVenta && (
                    <button
                      className="btn btn-outline-danger btn-lg me-2 w-100 w-md-auto"
                      onClick={() => NoteditarVentasHandler()}
                    >
                      Cancelar
                    </button>
                  )}
                  <button
                    className="btn btn-outline-success btn-lg w-100 w-md-auto"
                    onClick={() =>
                      editarVenta
                        ? actualizarVentaHandler()
                        : editarVentasHandler()
                    }
                  >
                    {editarVenta ? "Actualizar Venta" : "Editar Venta"}
                  </button>
                </div>
              </div>
              <div
                className={
                  Number(local.kpi) <= Number(venta.venta_total)
                    ? "alert alert-primary"
                    : "alert alert-danger"
                }
                role="alert"
              >
                <h2>Ventas del Día {formatoFecha(venta.createdAt)}</h2>
                <h2>
                  Venta ingresada por: {venta.perfil.nombres}{" "}
                  {venta.perfil.apellidos}
                </h2>
              </div>
              <div
                className={
                  Number(local.kpi) <= Number(venta.venta_total)
                    ? "alert alert-success"
                    : "alert alert-danger"
                }
                role="alert"
              >
                <div className="row mb-4">
                  <h2>Ventas por Empleado</h2>
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>Empleado</th>
                        <th>Cantidad de Clientes Atendidos</th>
                        <th>Total Ventas</th>
                        <th>Detalles</th>
                      </tr>
                    </thead>
                    <tbody>
                      {editarVenta
                        ? editarVentasEmpleados.map((empleado) => {
                            return (
                              <tr key={empleado.usuario_id}>
                                <td>{empleado.nombre}</td>
                                <td>
                                  <Form.Control
                                    type="text"
                                    name={empleado.id}
                                    placeholder="Cantidad Cliente"
                                    className="w-100 text-center"
                                    value={empleado.cantidadCliente}
                                    onChange={(event) =>
                                      editarActualizarCantidadCliente(
                                        empleado.id,
                                        event
                                      )
                                    }
                                  />
                                </td>
                                <td>{formatearAPesos(empleado.monto_total)}</td>
                                <td>
                                  {empleado.servicios.length === 0 ? (
                                    <div className="text-bg-danger rounded-2">
                                      No hay Servicios
                                    </div>
                                  ) : (
                                    <button
                                      className="btn btn-sm btn-outline-info"
                                      onClickCapture={() =>
                                        editarVerDetallesVenta(
                                          empleado.servicios,
                                          empleado.nombre
                                        )
                                      }
                                    >
                                      Detalles
                                    </button>
                                  )}
                                </td>
                              </tr>
                            );
                          })
                        : ventasEmpleados.map((empleado) => {
                            totalVentaEmpleados += Number(empleado.monto_total);
                            return (
                              <tr key={empleado.usuario_id}>
                                <td>{empleado.nombre}</td>
                                <td>{empleado.cantidadCliente}</td>
                                <td>{formatearAPesos(empleado.monto_total)}</td>
                                <td>
                                  {empleado.servicios.length === 0 ? (
                                    <div className="text-bg-danger rounded-2">
                                      No hay Servicios
                                    </div>
                                  ) : (
                                    <button
                                      className="btn btn-sm btn-outline-info"
                                      onClickCapture={() =>
                                        verDetallesEmpleado(
                                          empleado.servicios,
                                          empleado.nombre
                                        )
                                      }
                                    >
                                      Detalles
                                    </button>
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                    </tbody>
                  </table>

                  <Modal
                    show={verDetalles}
                    onHide={() => cerrarDetallesVenta()}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>
                        Detalles Venta de: {nombreEmpleado}{" "}
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <Table striped bordered hover>
                        <thead>
                          <tr>
                            <th scope="col">Nombre Servicio</th>
                            <th scope="col">Monto Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          {detalles.map((det) => (
                            <tr key={det.id}>
                              <td>{det.nombreServicio}</td>
                              <td>{formatearAPesos(det.venta_total)}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant="info"
                        onClick={() => cerrarDetallesVenta()}
                      >
                        Cerrar
                      </Button>
                    </Modal.Footer>
                  </Modal>

                  <Modal
                    show={editarVerDetalles}
                    onHide={() => editarCerrarDetallesVenta()}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>
                        Detalles Venta de: {nombreEmpleado}{" "}
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <Table striped bordered hover>
                        <thead>
                          <tr>
                            <th scope="col">Nombre Servicio</th>
                            <th scope="col">Monto Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          {editarDetalles.map((det) => (
                            <tr key={det.id}>
                              <td>{det.nombreServicio}</td>
                              <td>
                                <Form.Control
                                  type="text"
                                  name={det.id}
                                  value={(() => {
                                    const servicioVenta =
                                      editarVentasEmpleados.find(
                                        (v) => v.id === det.venta_empleado_id
                                      );
                                    const monto = servicioVenta.servicios.find(
                                      (ser) =>
                                        ser.servicio_id === det.servicio_id
                                    );
                                    return formatearAPesos(monto.venta_total);
                                  })()}
                                  placeholder="Monto total"
                                  onChange={(event) =>
                                    editarActualizarValorDetalles(
                                      event,
                                      det.servicio_id,
                                      det.usuario_id
                                    )
                                  }
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant="info"
                        onClick={() => editarCerrarDetallesVenta()}
                      >
                        Cerrar
                      </Button>
                    </Modal.Footer>
                  </Modal>

                  <div className="mt-4">
                    <h5>
                      Total de Ventas de Empleados:{" "}
                      {editarVenta
                        ? formatearAPesos(editarTotalVentasEmpleados)
                        : formatearAPesos(totalVentaEmpleados)}
                    </h5>
                  </div>
                </div>
              </div>
              <div
                className={
                  Number(local.kpi) <= Number(venta.venta_total)
                    ? "alert alert-success"
                    : "alert alert-danger"
                }
                role="alert"
              >
                <div className="row mb-4">
                  <h2>Ventas por Método de Pago</h2>

                  {editarVenta ? (
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>Método de Pago</th>
                          <th>Total Ventas</th>
                        </tr>
                      </thead>
                      <tbody>
                        {editarMetodosPagos.map((metodo) => {
                          totalVentaMetodos += Number(metodo.total);
                          return (
                            <tr key={metodo.id}>
                              <td>{metodo.nombre}</td>
                              <td>
                                <Form.Control
                                  type="text"
                                  name={metodo.id}
                                  className="w-100 text-center"
                                  value={
                                    metodo.total !== "0"
                                      ? `${formatearAPesos(metodo.total)}`
                                      : "No hubo venta"
                                  }
                                  onChange={(event) =>
                                    editarActualizarValorMetodoPago(
                                      metodo.id,
                                      event
                                    )
                                  }
                                />
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  ) : (
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>Método de Pago</th>
                          <th>Total Ventas</th>
                        </tr>
                      </thead>
                      <tbody>
                        {metodosPagos.map((metodo) => {
                          totalVentaMetodos += Number(metodo.total);
                          return (
                            <tr key={metodo.id}>
                              <td>{metodo.nombre}</td>
                              <td>
                                {metodo.total !== "0"
                                  ? `${formatearAPesos(metodo.total)}`
                                  : "No hubo venta"}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  )}
                  <div className="mt-4">
                    <h5>
                    Total de Ventas Métodos de Pago:{" "}
                    {formatearAPesos(totalVentaMetodos)}
                    </h5>
                  </div>
                </div>
              </div>
              <div
                className={
                  Number(local.kpi) <= Number(venta.venta_total)
                    ? "alert alert-success"
                    : "alert alert-danger"
                }
                role="alert"
              >
                <div className="row mb-4">
                  <h2>Descuentos y Comentarios</h2>
                  {editarVenta ? (
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>Descuento</th>
                          <th>Comentario</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr key={editarDescuentos.id}>
                          <td>
                            <Form.Control
                              type="text"
                              name={editarDescuentos.id}
                              className="text-center"
                              value={
                                editarDescuentos.descuento !== "0"
                                  ? `${formatearAPesos(
                                      editarDescuentos.descuento
                                    )}`
                                  : "No se aplicó descuento"
                              }
                              placeholder="Descuento"
                              onChange={(event) =>
                                editarActualizarDescuento(event)
                              }
                            />
                          </td>
                          <td>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              name="comentarios"
                              placeholder="Ingrese un comentario si exite un descuento"
                              className="w-100"
                              value={editarDescuentos.comentario}
                              required={editarDescuentos.descuentos === 0 ? false : true}
                              onChange={(e) => editarComentarios(e.target.value)}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  ) : (
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>Descuento</th>
                          <th>Comentario</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr key={descuentos.id}>
                          <td>
                            {descuentos.descuento !== "0"
                              ? `${formatearAPesos(descuentos.descuento)}`
                              : "No se aplicó descuento"}
                          </td>
                          <td>{descuentos.comentario || "Sin comentario"}</td>
                        </tr>
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
              <div
                className={
                  Number(local.kpi) <= Number(venta.venta_total)
                    ? "alert alert-info"
                    : "alert alert-danger"
                }
                role="alert"
              >
                <div className="mt-4 py-5">
                  <h4>
                    Total de Venta del Día: 
                    {editarVenta?(formatearAPesos(editarTotalVentasEmpleados - editarDescuentos.descuento))
                    :(formatearAPesos(venta.venta_total))}
                  </h4>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default VerVenta;
