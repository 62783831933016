import React from "react";

const DashBoardEmpleado = () => {
  return (
    <div className="container">
      <h1>Hola mundo desde empleado</h1>
      <h1>Hola mundo desde empleado</h1>
      <h1>Hola mundo desde empleado</h1>
      <h1>Hola mundo desde empleado</h1>
      <h1>Hola mundo desde empleado</h1>
    </div>
  );
};

export default DashBoardEmpleado;
